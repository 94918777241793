import { Container } from "@mui/material";
import * as React from "react";
import Lottie from "react-lottie";
import { Community, Header } from "../components";
import Footer from "../components/Footer";
import PageTitle from "../components/PageTitle";
import featuresBg from "../images/features.png";
import claims from "../images/features/claims.svg";
import earnYield from "../images/features/earn-yield.png";
import instantPayout from "../images/features/instant-payout.png";
import * as lottieEarnYield from "../images/features/lottie-earn-yield.json";
import * as lottieInstantPayout from "../images/features/lottie-instant-payout.json";
import * as lottiePoweredByPolygon from "../images/features/lottie-polygon.json";
import * as lottieUnrivalledReturns from "../images/features/lottie-unrivalled.json";
import * as lottieVastCoverage from "../images/features/lottie-vast-coverage.json";
import pools from "../images/features/pools.svg";
import poweredByPolygon from "../images/features/powered-by-polygon.png";
import pricing from "../images/features/pricing.svg";
import protocol from "../images/features/protocol.svg";
import unrivalledReturns from "../images/features/unrivalled-returns.png";
import vastCoverage from "../images/features/vast-coverage.png";
import "../styles/features.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Hat } from "../components/Header";

const Features = () => {
  const lottieInstantPayoutData = {
    loop: true,
    autoplay: true,
    animationData: lottieInstantPayout,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const lottieVastCoverageData = {
    loop: true,
    autoplay: true,
    animationData: lottieVastCoverage,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const lottiePoweredByPolygonData = {
    loop: true,
    autoplay: true,
    animationData: lottiePoweredByPolygon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const lottieUnrivalledReturnsData = {
    loop: true,
    autoplay: true,
    animationData: lottieUnrivalledReturns,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const lottieEarnYieldData = {
    loop: true,
    autoplay: true,
    animationData: lottieEarnYield,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  React.useEffect(() => {
    AOS.init({
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <React.Fragment>
      <Hat title="Features" />
      <Header />
      <PageTitle
        title="Community-Driven P2P Marketplace"
        description={
          <p>
            Insured Finance is a decentralized peer-to-peer marketplace.
            <br /> Insurance contracts are fulfilled by marketplace members and
            it is the first marketplace that facilitates
            <br /> tailored insurance requests.
          </p>
        }
        background={featuresBg}
      />
      <div className="features">
        <div className="features__challenges">
          <Container>
            <div className="title" data-aos="zoom-in">
              <h1>Challenges of Current DeFi Insurance</h1>
            </div>
            <div className="content-tile" data-aos="fade-up">
              <div className="tile">
                <img src={pools} width="40px" height="40px" />
                <h3>
                  Limited Insurance
                  <br /> Pools
                </h3>
                <ul>
                  <li>Pre-Set-Coverage-Pools</li>
                  <li>No individual and custom coverage</li>
                  <li>No rug pull protection</li>
                </ul>
              </div>
              <div className="tile">
                <img src={claims} width="40px" height="40px" />
                <h3>
                  Complicated Claims
                  <br /> Assessment
                </h3>
                <ul>
                  <li>Payouts can take months</li>
                  <li>Complicated claims process</li>
                  <li>Complicating voting process for claims</li>
                </ul>
              </div>
            </div>
            <div className="content-tile" data-aos="fade-up">
              <div className="tile">
                <img src={pricing} width="40px" height="40px" />
                <h3>
                  Unacceptable Pricing
                  <br /> and Collateralization
                </h3>
                <ul>
                  <li>High premiums</li>
                  <li>Insufficient collaterization reserves</li>
                  <li>Counterparty risk</li>
                </ul>
              </div>
              <div className="tile">
                <img src={protocol} width="40px" height="40px" />
                <h3>Protocol Limitations</h3>
                <ul>
                  <li>High fees on Ethereum-based insurance platforms</li>
                  <li>Slow transactions</li>
                  <li>Greater smart contract risk</li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
        <div className="features__section">
          <Container>
            <div className="title" data-aos="fade-up">
              <h1>Why Choose Insured Finance?</h1>
            </div>
            <div className="feature-wrapper">
              <div className="feature-description" data-aos="fade-right">
                <h3>Instant Payouts and Full Collateralization</h3>
                <p>
                  Insured Finance smart contracts ensure that payouts are made
                  <br />
                  immediately after an event has occurred.
                </p>
              </div>
              <div className="feature-image" data-aos="flip-left">
                <img src={instantPayout} width="248px" height="220px" />
                <div className="feature-animation">
                  <Lottie
                    options={lottieInstantPayoutData}
                    height={175}
                    width={175}
                  />
                </div>
              </div>
            </div>
            <div className="feature-wrapper">
              <div className="feature-image" data-aos="flip-right">
                <img src={unrivalledReturns} width="248px" height="220px" />
                <div
                  className="feature-animation"
                  style={{ bottom: "-25px", right: "-10px" }}
                >
                  <Lottie
                    options={lottieUnrivalledReturnsData}
                    height={175}
                    width={175}
                  />
                </div>
              </div>
              <div className="feature-description" data-aos="fade-left">
                <h3>Unrivalled Returns</h3>
                <p>
                  INFI token holders earn lucrative returns. The funds in
                  insurance
                  <br /> contracts are allocated to stable investments and
                  returns are
                  <br /> distributed to INFI token holders quarterly.
                </p>
              </div>
            </div>
            <div className="feature-wrapper">
              <div className="feature-description" data-aos="fade-right">
                <h3>Vast Coverage Capabilities</h3>
                <p>
                  Insured Finance users can get comprehensive coverage from
                  <br /> their web-based wallet with no need for KYC.
                </p>
              </div>
              <div className="feature-image" data-aos="flip-left">
                <img src={vastCoverage} width="248px" height="220px" />
                <div
                  className="feature-animation"
                  style={{ bottom: "-25px", right: "-25px" }}
                >
                  <Lottie
                    options={lottieVastCoverageData}
                    height={175}
                    width={175}
                  />
                </div>
              </div>
            </div>
            <div className="feature-wrapper">
              <div className="feature-image" data-aos="flip-right">
                <img src={earnYield} width="248px" height="220px" />
                <div
                  className="feature-animation"
                  style={{ bottom: "0px", right: "-50px" }}
                >
                  <Lottie
                    options={lottieEarnYieldData}
                    height={175}
                    width={175}
                  />
                </div>
              </div>
              <div className="feature-description" data-aos="fade-left">
                <h3>Earn Yield</h3>
                <p>
                  Those that provide coverage earn the entire premium from each
                  <br /> contract. The riskier the coverage, the greater the
                  premium.
                </p>
              </div>
            </div>
            <div className="feature-wrapper">
              <div className="feature-description" data-aos="fade-right">
                <h3>Powered by Polygon</h3>
                <p>
                  Insured Finance operates on the Polygon blockchain.
                  <br /> Everything is transparent. Claims and collateralization
                  can be
                  <br /> easily audited on-chain.
                </p>
              </div>
              <div className="feature-image" data-aos="flip-left">
                <img src={poweredByPolygon} width="248px" height="220px" />
                <div
                  className="feature-animation"
                  style={{ bottom: "30px", right: "20px" }}
                >
                  <Lottie
                    options={lottiePoweredByPolygonData}
                    height={100}
                    width={100}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Community />
      <Footer />
    </React.Fragment>
  );
};

export default Features;
